import * as Yup from "yup";
import React from "react";
import { database } from "components/config";

import { Container, Typography, TextField, Button } from "@mui/material";
import { useFormik } from "formik";

import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const phoneRegExp = /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/;

const validationSchema = Yup.object({
  name: Yup.string().min(5, "it's too short").required("Required"),
  email: Yup.string().email("Enter a valid Email").required("Required"),
  phone: Yup.string()

    .matches(phoneRegExp, "Enter a valid no.")
    .required("Required"),
  message: Yup.string().min(10, "it's too short").required("Required"),
});

const ContactUs = () => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, props) => {
      alert(JSON.stringify(values, null, 2));
      console.log(values);

      database
        .ref("/contacts")
        .push({
          timestamp: Date.now(),
          name: values.name,
          email: values.email,
          phone: values.phone,
          message: values.message,
          sentFrom: "balighara",
        })
        .catch((error) => {
          console.log(error);
        });
      props.resetForm();
    },
  });

  return (
    <>
      <div className="contact_bg_clr ">
        <Container className="main_contact_address_container flex_row">
          <div className="contact_address new_bg_clr flex_column">
            <div className="main_contact_title_div">
              <Typography
                variant="h6"
                className="new_text_2_clr"
                sx={{
                  fontSize: {
                    lg: 25,
                    md: 15,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                Get In Touch
              </Typography>
              <Typography
                variant="body1"
                className="new_text_1_clr padding_1_0"
              >
                We'd love to hear from you. Our friendly team is always here to
                chat
              </Typography>
            </div>
            <div className="gap_1 flex_row width100">
              <div>
                <EmailOutlinedIcon className="new_text_2_clr" />
              </div>
              <div>
                <Typography variant="h6" className="new_text_2_clr">
                  Chat to us
                </Typography>
                <Typography
                  variant="body2"
                  className="new_text_1_clr padding_1_0"
                >
                  Our team is here to help
                </Typography>
                <Typography variant="body2" className="new_text_2_clr">
                  balighara@gmail.com
                </Typography>
              </div>
            </div>
            <div className="gap_1 flex_row width100">
              <div>
                <AddLocationOutlinedIcon className="new_text_2_clr" />
              </div>
              <div>
                <Typography variant="h6" className="new_text_2_clr">
                  Office
                </Typography>
                <Typography
                  variant="body2"
                  className="new_text_1_clr padding_1_0"
                >
                  Come say hello at our office HQ
                </Typography>
                <Typography variant="body2" className="new_text_2_clr">
                  Plot no.100,bhimatangi, <br /> Bhubaneswar, Odisha, <br />
                  751002.
                </Typography>
              </div>
            </div>
            <div className="gap_1 flex_row width100">
              <div>
                <CallOutlinedIcon className="new_text_2_clr" />
              </div>
              <div>
                <Typography variant="h6" className="new_text_2_clr">
                  Phone
                </Typography>
                <Typography
                  variant="body2"
                  className="new_text_1_clr padding_1_0"
                >
                  24<i>X</i>7 open
                </Typography>
                <Typography variant="body2" className="new_text_2_clr">
                  0674-2740519 <br /> +91-7328841932 (Editorial) <br />
                  +91-7328841933(Business)
                </Typography>
              </div>
            </div>
            {/* <div className="gap_1 flex_row width100">
              <div>
                <LanguageIcon className="new_text_2_clr" />
              </div>
              <div>
                <Typography variant="h6" className="new_text_2_clr">
                  Website
                </Typography>
                <Typography
                  variant="body2"
                  className="new_text_1_clr padding_1_0"
                >
                  Visit our website again
                </Typography>
                <Typography variant="body2" className="new_text_2_clr">
                  balighara.org
                </Typography>
              </div>
            </div> */}

            <div className="followus_icons flex_around">
              <FacebookIcon className="new_text_2_clr" />
              <InstagramIcon className="new_text_2_clr" />
              <TwitterIcon className="new_text_2_clr" />
              <LinkedInIcon className="new_text_2_clr" />
            </div>
          </div>
          <div className="contact_container flex_column_0_center ">
            <div className="contact_title">
              <Typography
                variant="h3"
                className=" "
                sx={{
                  fontSize: {
                    lg: 33,
                    md: 30,
                    sm: 30,
                    xs: 27,
                  },
                }}
              >
                Contact Us !
              </Typography>
              <Typography variant="body1" className="color_para">
                You can reach us anytime via
                <span className="color_title"> balighara@gmail.com </span>
              </Typography>
            </div>

            <div className="contact_input_field flex_column">
              <form
                onSubmit={formik.handleSubmit}
                className="formik_form_div flex_column"
              >
                <div className="width100">
                  <Typography variant="body1"> Name</Typography>
                  <TextField
                    fullWidth
                    type="text"
                    placeholder="Enter Your Name"
                    name="name"
                    id="outlined-basic"
                    variant="outlined"
                    className="bg_white"
                    InputProps={{
                      classes: {
                        root: " ",
                        // notchedOutline: "notched_outline",
                        input: "form_textfield",
                      },
                    }}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="width100">
                  <Typography variant="body1"> Email</Typography>
                  <TextField
                    fullWidth
                    type="email"
                    placeholder="e.g. example@gmail.com"
                    name="email"
                    id="outlined-basic"
                    variant="outlined"
                    className="bg_white"
                    InputProps={{
                      classes: {
                        root: " ",
                        // notchedOutline: "notched_outline",
                        input: "form_textfield",
                      },
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="width100">
                  <Typography variant="body1"> Phone</Typography>
                  <TextField
                    fullWidth
                    type="phone"
                    placeholder="Enter Your Phone No."
                    name="phone"
                    id="outlined-basic"
                    variant="outlined"
                    className="bg_white"
                    InputProps={{
                      classes: {
                        root: " ",
                        // notchedOutline: "notched_outline",
                        input: "form_textfield",
                      },
                    }}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </div>
                <div className="width100">
                  <Typography variant="body1"> How can we help ?</Typography>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    placeholder="We'd Love To Hear From You 😊"
                    name="message"
                    className="bg_white"
                    InputProps={{
                      classes: {
                        root: "",
                        // notchedOutline: "notched_outline",
                      },
                    }}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                  />
                </div>

                <Button
                  type="submit"
                  variant="contained"
                  className="mui_btn new_bg_clr "
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
