import { useState, useEffect } from "react";
import { Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const PatrikaPhoto = ({ setIsOpen, patrikaPhotoArr, index }) => {
  const [curIndex, setCurIndex] = useState(index);
  // console.log(index, curIndex);
  useEffect(() => {
    if (curIndex > patrikaPhotoArr.length - 1) {
      setCurIndex(0);
    }
    if (curIndex < 0) {
      setCurIndex(patrikaPhotoArr.length - 1);
    }
    return curIndex;
  }, [curIndex, patrikaPhotoArr.length]);

  const slideImgLeft = () => {
    setCurIndex(curIndex - 1);
  };
  const slideImgRight = () => {
    setCurIndex(curIndex + 1);
  };

  return (
    <Container className="hello_ptrk">
      <div className="main_ptrk_img_container flex_center_center">
        <div className="ptrk_img_div">
          <img
            src={patrikaPhotoArr[curIndex]}
            alt="patrika"
            className="ptrk_img_div_src_img"
          />
          <div className="ptrk_btn_div flex_btw_cntr">
            <div className="ind_ptrk_btn_span">
              <ArrowBackIosNewIcon
                className="ind_ptrk_btn icon_clr"
                onClick={slideImgLeft}
              />
            </div>
            <div className="ind_ptrk_btn_span">
              <ArrowForwardIosIcon
                className="ind_ptrk_btn icon_clr"
                onClick={slideImgRight}
              />
            </div>
          </div>
        </div>
        <div className="img_fuctionality_div flex_between">
          <div className="flex_none_cntr icon_clr">
            <ArrowBackIosNewIcon className=" icon_clr" onClick={slideImgLeft} />
            {curIndex + 1}/{patrikaPhotoArr.length}
            <ArrowForwardIosIcon
              className=" icon_clr "
              onClick={slideImgRight}
            />
          </div>
          <div className="btn_close_div">
            <CloseIcon
              className="btn_close_icon icon_clr "
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PatrikaPhoto;
