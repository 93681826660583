import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";

export default function TemporaryDrawer() {
  const [state, setState] = useState({
    top: false,
  });
  const navigate = useNavigate();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          {
            navName: "home",
            path: "/",
          },
          {
            navName: "about us",
            path: "/",
          },
          // {
          //   navName: "the founder",
          //   path: "/founder",
          // },
          // {
          //   navName: "the editor",
          //   path: "/editor",
          // },
          {
            navName: "team",
            path: "/team",
          },
          {
            navName: "gallery",
            path: "/gallerypage",
          },
          {
            navName: "magazines",
            path: "/magazines",
          },
          {
            navName: "patrikahata",
            path: "/patrikahata",
          },
          {
            navName: "book publication",
            path: "/publication",
          },
          {
            navName: "events",
            path: "/events",
          },
          {
            navName: "contact",
            path: "/contact",
          },
        ].map((curElm, index) => (
          <ListItem
            className="drawer_menu_style"
            button
            key={curElm.navName}
            onClick={() => {
              navigate(curElm.path);
            }}
          >
            <ListItemText
              className="drawer_menu_text"
              primary={curElm.navName}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <>
        <Button className="burger" onClick={toggleDrawer("top", true)}>
          <MenuIcon />
        </Button>
        {/* <div> */}
        <Drawer
          anchor={"top"}
          open={state["top"]}
          onClose={toggleDrawer("top", false)}
        >
          {list("top")}
        </Drawer>
        {/* </div> */}
      </>
    </div>
  );
}
