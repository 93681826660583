import { Gallery } from "components/gallery";
import { FounderSection } from "components/foundersection";
const Home = () => {
  return (
    <>
      <Gallery />
      <FounderSection />
    </>
  );
};

export default Home;
