import React, { useRef, useEffect, useState } from "react";

import { Container, Typography } from "@mui/material";
import { img1, img2, img3, img4, img5, img6, img7, img8, img9 } from "assets";

import { ArrowForwardIos, ArrowBackIosNew } from "@mui/icons-material";

const newImgArr = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

const Magazines = () => {
  const [count, setCount] = useState(0);
  const divref = useRef(null);
  const newdivref = useRef(null);

  const handleScroll = (value) => {
    if (value === "left")
      divref.current?.scrollBy(-newdivref.current?.offsetWidth, 0);
    else {
      divref.current?.scrollBy(newdivref.current?.offsetWidth, 0);
    }
  };
  useEffect(() => {
    if (window.innerWidth > 650) {
      const divWidth = divref.current?.offsetWidth / 3;
      setCount(divWidth);
    } else {
      const divWidth = divref.current?.offsetWidth;
      setCount(divWidth);
    }
    return () => {
      setCount(null);
    };
  }, [count]);

  return (
    <Container>
      <div className="mag_title_div ">
        <Typography variant="h5" className="color_title">
          <b> THE BALIGHARA</b>
        </Typography>
        <Typography variant="body1" className="color_para">
          Premier monthly family magazine of Odisha
        </Typography>
        <Typography variant="body1" className="color_para">
          R.N.I. Regd. No.: ORI ORI/2000/1840 <br />
          ISSN No.: 2277 – 113
        </Typography>
      </div>
      <div className="mag_desc_div color_para">
        <Typography variant="body1">
          ‘The Balighara’ is the largest circulated (certified by ABC) monthly
          family magazine of Odisha with the largest readership. A comprehensive
          family magazine ‘The Balighara’ has captured the mindscape of all
          sections of people of the state. It has also created it’s space in the
          heart of Odias residing in the country and abroad. This attractive
          monthly magazine with its broad content-mix and quality printing,
          caters to the taste of all cultured Odias regardless of age, gender
          and income level. ‘The Balighara’ recipe of success is simple. It
          offers something for everyone. Every issue contains cover-features on
          topical social and cultural issues; life-style and fashion trends;
          movies and television; fiction, poetry, serial novels; Odia
          translations of classics from other languages; sports; health; beauty;
          cookery and household tips. The monthly magazine hits the stands with
          unfailing regularity on the 1st of every month. Apart from the regular
          issues ‘The Balighara’ brings out four special issues every year. Each
          issue, be it regular or special is a keepsake — a glossy work of art
          with multicoloured layouts so beautiful that it catches attention of
          all section of readers. Needless to say, with so much going for it
          ‘The Balighara’ provides the dream-vehicle for all to access the Odia
          cultural psyche.
        </Typography>
      </div>

      <div className="mag_btn_and_img_container">
        <div className="mag_btn_div flex_btw_cntr color_bg">
          <ArrowBackIosNew
            className="mag_btn_icon color_white"
            onClick={() => handleScroll("left")}
          />
          <Typography
            variant="h4"
            className="color_white"
            sx={{
              fontSize: {
                lg: 30,
                md: 20,
                sm: 20,
                xs: 20,
              },
            }}
          >
            <b> THE BALIGHARA </b>
          </Typography>
          <ArrowForwardIos
            className="mag_btn_icon color_white"
            onClick={() => handleScroll("right")}
          />
        </div>
        <div className="mag_img_main_div" ref={divref}>
          {newImgArr.map((curElm, index) => {
            return (
              <div
                key={index}
                className="mag_img_individual_div"
                ref={newdivref}
                style={{ width: `${count}px` }}
              >
                <img
                  src={curElm}
                  alt="balighara magazine"
                  className="mag_img"
                  // style={{ width: `${count}px` }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Magazines;
