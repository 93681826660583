import { Container } from "@mui/material";
import { SliderGallery } from ".";
import { img1, img2, img3, img4, img5, img6, img7, img8, img9 } from "assets";

const Gallery = () => {
  const imgArr = [img1, img2, img3, img4];
  const paperImgArr = [img5, img6, img7, img8, img9];

  return (
    <Container>
      <div className="main_gallery_container" id="gallery">
        <SliderGallery imgArr={imgArr} name="balighara" />
        <SliderGallery imgArr={paperImgArr} name="achievements" />
        <SliderGallery imgArr={imgArr} name="Events" />
      </div>
    </Container>
  );
};

export default Gallery;
