// import { Container } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { TemporaryDrawer } from ".";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";

const navMenu = [
  {
    name: "magazines",
    path: "/magazines",
  },
  {
    name: "patrika hata",
    path: "/patrikahata",
  },
  {
    name: "book publication",
    path: "/publication",
  },
  {
    name: "events",
    path: "/events",
  },
  {
    name: "contact",
    path: "/contact",
  },
  {
    name: "gallery",
    path: "/gallerypage",
  },
];

const NavMenu = () => {
  const [popup, setPopup] = useState(false);

  const size = useRef(null);

  const stickyNav = () => {
    if (window.scrollY > 60) {
      setPopup(true);
    } else {
      setPopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickyNav);
    return () => {
      window.removeEventListener("scroll", stickyNav);
    };
  });

  return (
    <>
      <div className="main_div">
        <div
          //   className="main_header_container"
          className={
            popup ? "main_header_container_deactive" : "main_header_container"
          }
        >
          {/* <div className="main_nav_menu"> */}

          <div className="nav_header_bg">
            <Container className="nav_header">
              <div className="left_nav_header">
                <span className="left_nav_header_span">
                  <CallIcon />
                  +91-9937093949
                </span>
                <span className="left_nav_header_span">
                  <EmailIcon />
                  baligharafoundation@gmail.com
                </span>
              </div>
              <div className="right_nav_header">
                <span className="nav_icons">
                  <i className="fab fa-facebook-square "></i>
                </span>

                <span className="nav_icons">
                  <i className="fab fa-twitter-square icon_margin"></i>
                </span>

                <span className="nav_icons">
                  <i className="fab fa-instagram"></i>
                </span>
              </div>
            </Container>
          </div>
          {/* </div> */}

          <div className="nav_logo">
            <img
              src="https://scontent.fbbi5-1.fna.fbcdn.net/v/t1.6435-9/176405968_188056883157369_8684257164928330991_n.png?_nc_cat=103&ccb=1-5&_nc_sid=973b4a&_nc_ohc=cPJp7BJG04kAX85otFt&_nc_ht=scontent.fbbi5-1.fna&oh=00_AT8XGNv9MdWgcsDq1ukre2Tr1049SVKVqK1GgntFplpAxA&oe=61FBD545"
              alt="balighara logo"
            />
          </div>
          {/* <Container> */}
          <Container className="new_nav_bar">
            <ul className="new_nav_ul">
              <li className="hover_aboutus">
                <NavLink to="/" className="new_nav_ul_li_a">
                  Home
                </NavLink>
              </li>
              <li className="hover_aboutus">
                <span className="new_nav_ul_li_a ">About Us</span>
                <div className="hover_nav_hide">
                  <span className="hover_nav_hide_span">
                    <NavLink to="/founder" className="new_nav_ul_li_a">
                      THE FOUNDER
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/editor" className="new_nav_ul_li_a">
                      THE EDITOR
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/team" className="new_nav_ul_li_a">
                      TEAM
                    </NavLink>
                  </span>
                </div>
              </li>
              {navMenu.map((curElm, index) => {
                return (
                  <li key={index}>
                    <NavLink to={curElm.path} className="new_nav_ul_li_a ">
                      {curElm.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </Container>
          {/* </Container> */}
        </div>
      </div>
      <div
        className={popup ? "popup_nav_menu" : "popup_nav_menu_deactive"}
        ref={size}
      >
        <Container className="popup_nav_container_css">
          <div className="popup_left_nav_menu">
            <img
              src="https://scontent.fbbi5-1.fna.fbcdn.net/v/t1.6435-9/176405968_188056883157369_8684257164928330991_n.png?_nc_cat=103&ccb=1-5&_nc_sid=973b4a&_nc_ohc=cPJp7BJG04kAX85otFt&_nc_ht=scontent.fbbi5-1.fna&oh=00_AT8XGNv9MdWgcsDq1ukre2Tr1049SVKVqK1GgntFplpAxA&oe=61FBD545"
              alt="balighara logo"
            />
          </div>
          <div className="popup_right_nav_bar">
            <ul className="popup_nav_ul">
              <li className="hover_aboutus">
                <NavLink to="/" className="new_nav_ul_li_a">
                  Home
                </NavLink>
              </li>
              <li className="hover_aboutus">
                <span className="new_nav_ul_li_a ">About Us</span>
                <div className="hover_nav_hide">
                  <span className="hover_nav_hide_span">
                    <NavLink to="/founder" className="new_nav_ul_li_a">
                      THE FOUNDER
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/editor" className="new_nav_ul_li_a">
                      THE EDITOR
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to="/team" className="new_nav_ul_li_a">
                      TEAM
                    </NavLink>
                  </span>
                </div>
              </li>

              {navMenu.map((curElm, index) => {
                return (
                  <li key={index}>
                    <NavLink to={curElm.path} className="new_nav_ul_li_a">
                      {curElm.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </Container>
      </div>

      <div className="main_drawer">
        <div className="drawer_menu">
          <TemporaryDrawer />
        </div>
        <img
          src="https://scontent.fbbi5-1.fna.fbcdn.net/v/t1.6435-9/176405968_188056883157369_8684257164928330991_n.png?_nc_cat=103&ccb=1-5&_nc_sid=973b4a&_nc_ohc=cPJp7BJG04kAX85otFt&_nc_ht=scontent.fbbi5-1.fna&oh=00_AT8XGNv9MdWgcsDq1ukre2Tr1049SVKVqK1GgntFplpAxA&oe=61FBD545"
          alt="balighara logo"
          className="drawer_nav_img"
        />
      </div>
    </>
  );
};

export default NavMenu;
