import { Container, Typography } from "@mui/material";

const Events = () => {
  return (
    <Container>
      <div className="main_event_container flex_center_center   color_white">
        <Typography
          variant="h4"
          className="event_title text_align_cent color_bg"
          sx={{
            fontSize: {
              lg: 30,
              md: 25,
              sm: 25,
              xs: 19,
            },
          }}
        >
          Awards
        </Typography>
        <hr className="event_hr_line" />
      </div>
      <div className="event_text_div flex_column color_para">
        <Typography variant="body1">
          Balighara Media Pvt. Ltd. has been established to achieve excellence
          in publication of magazines, books and production of audio,
          audiovisual programmes and films for upliftment of art, culture and
          literature. It has created a platform where the creative potential
          inherited in every individual can be realised. It has also provided
          space for eminent people in the field of art, literature, social
          service, education, media, administration and industries etc.
        </Typography>
        <Typography variant="body1">
          The prestigious ‘Balighara Sammana’ is presented to an individual for
          his/her eye catching contribution in the field of art, literature,
          social service, education, media, administration and industries. The
          famous personalities who are honoured with ‘Balighara Sammana’ include
          eminent actor Hemanta Das (2003), eminent singer Bhikari Charan Bal
          (2004), eminent writer Pandita Prabodh Kumar Mishra (2005), eminent
          writer and journalist Manorama Mohapatra (2006), eminent singer
          Raghunath Panigrahi (2007), eminent social activist Padmashree Dr.
          Tulasi Munda (2008), eminent writer and administrator Tarunakanti
          Mishra (2009), eminent actor and educationst Sarat Chandra Pujari
          (2010), eminent translator Shankarlal Purohit (2011), eminent musician
          Prafulla Kar (2012), the famous sambalpuri song “Rangabati…” (2013);
          written by Mitrabhanu Gountia, composed by Prabhudutta Pradhan and
          sung by Krishna Patel and Jitendra Haripal, eminent educationist &
          social worker Sruti Mohapatra (2014), eminent musician Shantanu
          Mohapatra (2015), eminent Odissi dancer Kumkum Mohanty (2016), eminent
          actor Uttam Mohanty (2017) and eminent social worker Sumani Jhodia
          (2018).
        </Typography>
        <Typography variant="body1">
          In Orissa, Balighara Media Pvt. Ltd. has created a possibility of
          gathering and interaction of writers, poets and readers. Every year it
          organises Balighara Literary Festival inviting veterans from Orissa
          and outside to share their experience and to discuss on the various
          issues and trends with thousands of writers. This is a unique literary
          festival for writers and poets to revive memories, meet old friends
          and to create new friends. In this festival ‘Balighara Sahitya
          Sammana’ is presented to eminent writers in recognition of their
          contribution to the field of literature. The ‘Balighara Sahitya
          Sammana’ awardees are: Gayatri Basumalick & Kalpana Kumari Devi
          (2008), Mohapatra Nilamani Sahu & Kuntala Kumari Acharya (2009),
          Chandra Sekhar Rath & Binapani Mohanty (2010), Santanu Kumar Acharya
          (2011), Achyutananda Pati (2012), Ramakanta Rath (2013), Janaki Ballav
          Pattnaik (2014), Satakadi Hota (2015), Rajendra Kishore Panda (2016),
          Manoj Das (2017), Soubhagya Kumar Mishra (2018) and Satyabadi Rout
          (2019). This prestigious award contains cash prise of Rupees One Lakh,
          an emblem and a certificate. From 2019 The Founder of ‘The Balighara’
          Dr. Achyuta Samanta established the prestigious ‘Nilimarani Sahitya
          Sammna (at National Level) in the memory of his great mother Smt.
          Nilimarani Samanta, who was an ordinary woman with extraordinary
          deeds. She was a holy mother, who brought up seven children alone
          amidst abject poverty after losing her husband in her forties. She
          never gave up hope and transmitted positive noble thoughts of
          education, honesty and humility to her children by fighting all
          adversity, facing criticism, keeping patience throughout her life. Her
          life was a reflection of the incessant struggle that numerous women
          put forward against the prevalent societal norms. This will be awarded
          to an individual for his/her contribution to Indian literature. The
          award carries a cash prize of Rupees Five Lakhs, an emblem and a
          certificate. The award will be presented each year in Balighara
          Literature Festival. The award will be decided by an independent panel
          of jury. First ‘Nilimarani Sahitya Sammna-2019’ is presented to
          eminent writer Prof. Manoj Das.
        </Typography>
        <Typography variant="body1">
          Also ‘Balighara Sampadaka Sammana’ is presented in this seminar to an
          individual Editor of a magazine every year in recognition of his/her
          lifetime contribution to editing of a magazine. This prestigious award
          contains citation and memento. The ‘Balighara Sampadaka Sammana’
          awardees are: editor of ‘Amrutayana’ Gita Hota (2012); editor of
          ‘Samaroha’ Sailarani Mishra (2013); editor of ‘Banichitra’ Parikshita
          Jena (2014); editor of ‘Sansara’ Anasuya Nanda (2015); editor of
          ‘Gokarnika’ Ramakanta Jena (2016), editor of ‘Istahara’ Nityananda
          Satapathy (2017), editor of ‘Satabhisa’ Sakuntala Baliyarsingh (2018)
          and editor of ‘Katha Katha Kabita Kabita’ Akshaya Behera (2019).
        </Typography>
        <Typography variant="body1">
          ‘Balighara Sahitya Sambardhana’ is presented in this literary festival
          to eminent personalities having dignified contribution in the field of
          literature and culture from the year 2016. Apart from the above
          routine functions, Balighara Media believes that magazines are the
          conservators of a Nation’s literary and cultural heritage. With this
          in view Balighara Media in the year 2009 launched a unique programme
          ‘Balighara Patrikaa Haata’ (unique & only magazine fair of the state)
          inaugurated by the Hon’ble Governor of Orissa Shri M.C. Bhandare. The
          idea was to facilitate the exhibition and sale of all the magazines of
          the state whether in print or out of print under one roof. Balighara
          made all the arrangements from providing free space and food for the
          participants free of cost. Pleasantly surprising everybody the Haata
          become a huge success from the start itself. It has become an annual
          event of magazine display and sale. ‘The Balighara’ hosts the function
          and provides free space for stalls. A day long exhibition comprising
          of more than 500 participants goes on with enthusiastic feelings of
          all participants. It is highly appreciated by the illustrious
          personalities present on the occasion. This year a new category of
          publication, the souvenir of colleges is included to Patrikaa Hata.
          Then on, year after year we are organising the haata in the month of
          January coinciding with the Balighara Literary Festival. Last year
          more than five hundred magazines participated in the haata. The
          success of the haata, where the editor gets a chance to interact with
          his readers directly has tempted the magazine owners of other states
          to participate in it. Their number is also rising every year.
        </Typography>
      </div>
    </Container>
  );
};

export default Events;
