import "./css/index.css";
import { NavMenu } from "components/navbar";
import { BrowserRouter } from "react-router-dom";
import { FooterSection } from "components/footer";
import RouterPath from "components/pages/routes/RouterPath";
const App = () => {
  return (
    <>
      <BrowserRouter>
        <NavMenu />
        <RouterPath />
        <FooterSection />
      </BrowserRouter>
    </>
  );
};

export default App;
