import { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const footerMenu = [
  {
    name: "home",
    path: "/",
  },
  {
    name: "The founder",
    path: "/founder",
  },
  {
    name: "The editor",
    path: "/editor",
  },
  {
    name: "balighara",
    path: "/",
  },
  {
    name: "contact",
    path: "/contact",
  },
];

const FooterSection = () => {
  const [isScroll, setIsScroll] = useState(false);
  const scrollArrow = () => {
    if (window.scrollY > 400) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollArrow);
    return () => {
      window.removeEventListener("scroll", scrollArrow);
    };
  });

  //   checking
  //   useEffect(() => {
  //     console.log(window.scrollY);
  //   });

  return (
    <>
      <div className="main_footer_bg">
        <Container className="main_footer_container">
          <div className="footer_logo_div">
            <img
              src="https://scontent.fbbi5-1.fna.fbcdn.net/v/t1.6435-9/176405968_188056883157369_8684257164928330991_n.png?_nc_cat=103&ccb=1-5&_nc_sid=973b4a&_nc_ohc=cPJp7BJG04kAX85otFt&_nc_ht=scontent.fbbi5-1.fna&oh=00_AT8XGNv9MdWgcsDq1ukre2Tr1049SVKVqK1GgntFplpAxA&oe=61FBD545"
              alt="balighara logo"
            />
          </div>

          <div className="footer_nav_div">
            <ul className="footer_ul">
              {footerMenu.map((curElm, index) => {
                return (
                  <li key={index}>
                    <NavLink to={curElm.path} className="footer_ul_a">
                      {curElm.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <hr className="footer_hr_width" />
          <div className="footer_text_div">
            <Typography variant="body2">
              Copyright © {new Date().getFullYear()} balighara.org. All Rights
              Reserved
            </Typography>
          </div>
          <div
            // className="footer_up_arrow footer_Up_arrow_deactive"
            className={
              isScroll ? "footer_up_arrow" : "footer_Up_arrow_deactive"
            }
            onClick={() => window.scrollTo(0, 0)}
          >
            <KeyboardDoubleArrowUpIcon className="footer_up_arrow_icon" />
          </div>
        </Container>
      </div>
    </>
  );
};

export default FooterSection;
