import { Button, Container, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const Founder = () => {
  return (
    <>
      <div className="main_founder_container  " id="founder">
        <Container className="container_div_style">
          <div className="individual_founder_section">
            <span className="founder_text_div">
              <Typography variant="h5">The Founder</Typography>
            </span>

            <div className="founder_img_div">
              <img
                src="https://image.freepik.com/free-photo/portrait-positive-confident-businessman_1262-17122.jpg"
                alt="founder"
                className="founder_img_div_srcimg"
              />
              <span className="blank_div_text_span">
                <Typography variant="h4" className="blank_div_text">
                  The Founder
                </Typography>
              </span>
            </div>

            <div className="founder_about">
              <Typography variant="body1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatum doloremque, expedita vel adipisci laudantium
                temporibus eos in soluta commodi possimus culpa quasi facere
                molestiae autem cupiditate saepe natus unde dolores!
              </Typography>
              <Button variant="outlined" endIcon={<InfoIcon />}>
                Know More
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Founder;
