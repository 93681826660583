import { Container, Typography } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(Title, Category, Author, Price) {
  return { Title, Category, Author, Price };
}

const rows = [
  createData("Janha Mamu", "Story", "Kishan Kumar", "100/-"),
  createData("Wings Of Fire", "Novel", "Laushik Giri", " 200/-"),
  createData("Panchatantra", "Story", "Chinmay Muduli", "300/-"),
  createData("Kalijai Ra Sandhya", "Poetry", "Rahul Pradhan", "400/-"),
  createData("Matrubhumi", "poetry", "Srinu Reddy", "500/-"),
];

const Publication = () => {
  return (
    <Container>
      <div className="book_title_div">
        <Typography variant="h6" className="book_title_text color_title">
          BOOK PUBLICATIONS
        </Typography>
        <Typography variant="body1" className="book_para_text color_para">
          balighara Media Pvt. Ltd. publishes Magazines, Novels, Stories,
          Poetries, Prose Collection, Feature Collection, Biography,
          Autobiography, Translations from other languages of eminent writers,
          children literature and Recipe Collection etc. Such publications
          attract readers and lovers of literature to the literary works of
          excellency.
        </Typography>
      </div>
      <div className="book_table_div ">
        <Typography variant="body1" className="book_text color_title">
          Our Publication :
        </Typography>
        <div className="book_name flex_center_center">
          <TableContainer component={Paper} className="book_table">
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Title</StyledTableCell>
                  <StyledTableCell align="center">Category</StyledTableCell>
                  <StyledTableCell align="center">Author</StyledTableCell>
                  <StyledTableCell align="center">Price</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      <b>
                        <i>{row.Title}</i>
                      </b>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row.Category}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.Author}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.Price}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </Container>
  );
};

export default Publication;
