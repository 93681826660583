import { Route, Routes } from "react-router-dom";
import {
  Home,
  ContactUs,
  Editor,
  Founder,
  Team,
  Events,
  Magazines,
  PatrikaHata,
  Publication,
  GalleryPage,
} from "components/pages";

const RouterPath = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/founder" element={<Founder />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/team" element={<Team />} />
        <Route path="/magazines" element={<Magazines />} />
        <Route path="/patrikahata" element={<PatrikaHata />} />
        <Route path="/publication" element={<Publication />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/gallerypage" element={<GalleryPage />} />
      </Routes>
    </>
  );
};

export default RouterPath;
