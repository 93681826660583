import { useRef, useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const SliderGallery = ({ imgArr, name }) => {
  const [state, setState] = useState(0);

  const divRef = useRef(null);

  useEffect(() => {
    let timeOutFn = setTimeout(() => {
      divRef.current?.scrollBy(divRef.current?.offsetWidth, 0);
      if (state === imgArr.length - 1) {
        divRef.current?.scrollTo(0, 0);
      }
    }, 2000);

    return () => {
      clearTimeout(timeOutFn);
    };
  }, [state, imgArr.length]);

  return (
    <>
      <div className="first_gallery_section">
        <div>
          <li className="gallery_li gallery_li_a">{name}</li>
        </div>
        <div className="btn_img_div_container">
          <div
            className="slider_style "
            ref={divRef}
            onScroll={() => {
              setState(
                divRef.current?.scrollLeft / divRef.current?.offsetWidth
              );
            }}
          >
            {imgArr.map((curElm, index) => {
              return (
                <div className="indivisual_gallery_div" key={index}>
                  <img
                    src={curElm}
                    alt="magazine"
                    className="indivisual_gallery_div_img"
                  />
                </div>
              );
            })}
          </div>
          <div className="main_btn_style">
            <div
              className="btn_div"
              onClick={() => {
                divRef.current?.scrollBy(-divRef.current?.offsetWidth, 0);
              }}
            >
              <ArrowBackIosNewIcon className="individual_btn_style" />
            </div>

            <div
              className="btn_div"
              onClick={() => {
                divRef.current?.scrollBy(divRef.current?.offsetWidth, 0);
              }}
            >
              <ArrowForwardIosIcon className="individual_btn_style" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderGallery;
