import { Button, Container, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const founderArr = [
  {
    title: "The Founder",
    img: "https://image.freepik.com/free-photo/portrait-positive-confident-businessman_1262-17122.jpg",
    about:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum doloremque, expedita vel adipisci laudantium temporibus eos in   soluta commodi possimus culpa quasi facere molestiae autem cupiditate saepe natus unde dolores!",
  },
  {
    title: "The Editor",
    img: "https://image.freepik.com/free-photo/businesswoman-classic-black-suite-white_231208-4138.jpg",
    about:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum doloremque, expedita vel adipisci laudantium temporibus eos in   soluta commodi possimus culpa quasi facere molestiae autem cupiditate saepe natus unde dolores!",
  },
];

const FounderSection = () => {
  return (
    <>
      <div className="main_founder_container  " id="founder">
        <Container className="container_div_style">
          {founderArr.map((curElm, index) => {
            const { title, img, about } = curElm;
            return (
              <div className="individual_founder_section" key={index}>
                <span className="founder_text_div">
                  <Typography variant="h5">{title}</Typography>
                </span>

                <div className="founder_img_div">
                  <img
                    src={img}
                    alt="founder"
                    className="founder_img_div_srcimg"
                  />
                  <span className="blank_div_text_span">
                    <Typography variant="h4" className="blank_div_text">
                      {title}
                    </Typography>
                  </span>
                </div>
                {/* <Typography variant="h4" className="blank_div_text">
                    {title}
                  </Typography> */}

                <div className="founder_about">
                  <Typography variant="body1">{about}</Typography>
                  <Button variant="outlined" endIcon={<InfoIcon />}>
                    Know More
                  </Button>
                </div>
              </div>
            );
          })}
        </Container>
      </div>

      {/* <div style={{ height: "300vh" }}></div> */}
    </>
  );
};

export default FounderSection;
