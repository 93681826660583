import { useState } from "react";
import { Container, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PatrikaPhoto from "./PatrikaPhoto";
import { img1, img2, img3, img4, img5, img6 } from "assets";

const patrikaPhotoArr = [img1, img2, img3, img4, img5, img6];

const PatrikaHata = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(0);

  return (
    <Container>
      <div className="main_patrika_container">
        <Typography variant="body1">
          Balighara Media believes that magazines are the conservators of a
          Nation’s literary and cultural heritage. With this in view Balighara
          Media in the year 2009 launched a unique programme ‘Balighara Patrikaa
          Haata’, an unique & only magazine fair of the state.
        </Typography>
        <Typography variant="body1">
          The idea was to facilitate the exhibition and sale of all the
          magazines of the state whether in print or out of print under one
          roof. Balighara made all the arrangements from providing space to food
          for the participants free of cost. Pleasantly surprising everybody the
          haata become a huge success from the start itself. It has become an
          annual event of magazine display and sale. ‘The Balighara’ hosts the
          function and provides free space for stalls. A day long exhibition
          comprising of more than 500 participants goes on with enthusiastic
          feelings. It is highly appreciated by the illustrious personalities
          present on the occasion. We are organising the haata in the month of
          January coinciding with the ‘Balighara Literary Festival’.This year a
          new category of publication, the souvenir of colleges is included to
          Patrikaa Hata.
        </Typography>
        <div className="patrika_photo_section flex_center_center">
          {patrikaPhotoArr.map((curElm, index) => {
            return (
              <div className="individual_photo_div" key={index}>
                <img
                  src={curElm}
                  alt="patrika"
                  className="individual_photo_img_src"
                />
                <div className="hover_photo_div">
                  <AddIcon
                    className="hover_text_plus"
                    onClick={() => {
                      setIsOpen(true);
                      setState(index);
                    }}
                  />
                </div>
                {isOpen && (
                  <PatrikaPhoto
                    patrikaPhotoArr={patrikaPhotoArr}
                    index={state}
                    setIsOpen={setIsOpen}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default PatrikaHata;
