import FounderSection from "../../foundersection/FounderSection";
const Team = () => {
  return (
    <>
      <FounderSection />
    </>
  );
};

export default Team;
